import axios from "axios";

// Define base URL
const baseUrl = process.env.REACT_APP_AUTH_SERVICE_BASE_URL;

// Helper function to handle errors
const handleApiError = (error: any) => {
  const status = error.response?.status || 500;
  const message = error.response?.data?.message || "An unexpected error occurred.";
  return { status, error: true, message };
};

// API Call to fetch all admins
export const getAdmins = async () => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baseUrl}/auth/v1/admin`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { status: response.status, data: response.data, error: false };
  } catch (error) {
    console.error("Error fetching admins:", error);
    return handleApiError(error);
  }
};

// API Call to add an admin
export const addAdmin = async (adminData: any) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.post(`${baseUrl}/auth/v1/admin/add`, adminData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { status: response.status, data: response.data, error: false };
  } catch (error) {
    console.error("Error adding admin:", error);
    return handleApiError(error);
  }
};

// API Call to update an admin
export const updateAdmin = async (id: string, adminData: any) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.put(`${baseUrl}/auth/v1/admin/${id}`, adminData, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { status: response.status, data: response.data, error: false };
  } catch (error) {
    console.error("Error updating admin:", error);
    return handleApiError(error);
  }
};

// API Call to delete an admin
export const deleteAdmin = async (id: string) => {
  try {
    const token = localStorage.getItem("token");
    const response = await axios.delete(`${baseUrl}/auth/v1/admin/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return { status: response.status, data: response.data, error: false };
  } catch (error) {
    console.error("Error deleting admin:", error);
    return handleApiError(error);
  }
};
