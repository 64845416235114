import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Grid from "@mui/material/Grid";
import { SurgeEvent } from "../../types/surgeEvents";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import { createSurgePricingEvent } from "../../apis/surgePricing";
import CountryRegionSelect from "../../components/CountryRegionSelect";
import { CountryType, RegionType } from "../../types/earnings";
import {
  addButtonContainer,
  addSurgeEvent,
  backButtonContainer,
  gridContainer,
  gridItem,
  rootContainer,
} from "./styles/CreateSurgeEvents.styles";
import { jwtDecode } from "jwt-decode";

const initialSurgeEventData = {
  event_name: "",
  event_description: "",
  start_time: "",
  end_time: "",
  frequency: "",
  week_days: [0],
  month_day: 0,
  year_month: 0,
  year_day: 0,
  country: "",
  multiplier: 1,
};
const countries: Record<string, string> = {
  uk: "london-boroughs",
  france: "ile-de-france",
  "ivory-coast": "abidjan",
};
const CreateSurgeEvents = () => {
  const [eventData, setEventData] = useState(initialSurgeEventData);
  const [eventStartDate, setEventStartDate] = useState("");
  const [eventEndDate, setEventEndDate] = useState("");
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);

  const navigate = useNavigate();
  const goBack = () => navigate("/surge-events");
  const [adminCountry, setAdminCountry] = useState<string>("all");

  useEffect(() => {
    //fetch all the discounts data
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded.countryCode !== "all") setCountry(decoded.countryCode);
      setAdminCountry(decoded.countryCode);
    }
  }, []);

  // Method for handling the inputs and update it
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "week_days") {
      let weekDaysAsIntegerArray = convertStringToArray(event.target.value);
      setEventData({ ...eventData, week_days: weekDaysAsIntegerArray });
    } else {
      setEventData({ ...eventData, [event.target.name]: event.target.value });
    }
  };

  const convertStringToArray = (inputString: string) => {
    const arrayOfIntegers = inputString.split(",").map((num) => parseInt(num.trim(), 10));
    return arrayOfIntegers;
  };

  const onStartDateChange = (value: any) => {
    setEventStartDate(value.toISOString());
  };

  const onEndDateChange = (value: any) => {
    setEventEndDate(value.toISOString());
  };

  // Method to format the payload as per API request and create a event
  const createEvent = async () => {
    let payload: SurgeEvent = {
      event_name: eventData.event_name,
      event_start_date_time: eventStartDate,
      event_end_date_time: eventEndDate,
      event_description: eventData.event_description,
      hours_range: {
        start: eventData.start_time,
        end: eventData.end_time,
      },
      frequency: eventData.frequency,
      week_days: eventData.week_days,
      month_day: Number(eventData.month_day),
      year_day: {
        month: Number(eventData.year_month),
        day: Number(eventData.year_day),
      },
      country_code: adminCountry && adminCountry === "all" ? country?.country_code! : adminCountry,
      region_code:
        adminCountry && adminCountry === "all" ? city?.region_code! : countries[adminCountry],
      multiplier: Number(eventData.multiplier),
    };

    let response = await createSurgePricingEvent(payload);
    if (response?.status) {
      setEventData(initialSurgeEventData);
      navigate("/surge-events");
    }
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={rootContainer}>
          <Box>
            <Typography style={addSurgeEvent}>Add Surge Event</Typography>
            <Box style={backButtonContainer}>
              <Button variant="contained" onClick={goBack} startIcon={<BackIcon />}>
                Back
              </Button>
            </Box>
            <Box sx={gridContainer}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-basic"
                    size="small"
                    placeholder="Event name"
                    label="Event name"
                    name="event_name"
                    variant="outlined"
                    style={gridItem}
                    onChange={onHandleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={onStartDateChange}
                      label="Event Start Date"
                      slotProps={{
                        textField: {
                          size: "small",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      onChange={onEndDateChange}
                      label="Event End Date"
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="standard-basic"
                    size="small"
                    placeholder="Description"
                    label="Description"
                    name="event_description"
                    variant="outlined"
                    style={gridItem}
                    onChange={onHandleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="standard-basic"
                    size="small"
                    placeholder="Start time (HH:MM)"
                    label="Start time"
                    name="start_time"
                    variant="outlined"
                    style={gridItem}
                    onChange={onHandleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="standard-basic"
                    size="small"
                    placeholder="End time (HH:MM)"
                    label="End time"
                    name="end_time"
                    variant="outlined"
                    style={gridItem}
                    onChange={onHandleChange}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" size="small">
                      Frequency
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      value={eventData.frequency}
                      label="Frequency"
                      onChange={(event) => {
                        setEventData({ ...eventData, frequency: event.target.value as string });
                      }}
                    >
                      <MenuItem value={"ONCE"}>Once</MenuItem>
                      <MenuItem value={"DAILY"}>Daily</MenuItem>
                      <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                      <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                      <MenuItem value={"YEARLY"}>Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {eventData.frequency === "WEEKLY" && (
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      placeholder="Days of the week (0,1,2)"
                      label="Days of the week"
                      name="week_days"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                      disabled={eventData.frequency !== "WEEKLY"}
                    />
                  </Grid>
                )}
                {eventData.frequency === "YEARLY" && (
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="number"
                      placeholder="Year Month (1-12)"
                      label="Year Month"
                      name="year_month"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                      disabled={eventData.frequency !== "YEARLY"}
                    />
                  </Grid>
                )}
                {eventData.frequency === "YEARLY" && (
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="number"
                      placeholder="Year Day (1-31)"
                      label="Year Day"
                      name="year_day"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                      disabled={eventData.frequency !== "YEARLY"}
                    />
                  </Grid>
                )}
                {eventData.frequency === "MONTHLY" && (
                  <Grid item xs={3}>
                    <TextField
                      id="standard-basic"
                      size="small"
                      type="number"
                      placeholder="Month Day (1-31)"
                      label="Month Day"
                      name="month_day"
                      variant="outlined"
                      style={gridItem}
                      onChange={onHandleChange}
                      disabled={eventData.frequency !== "MONTHLY"}
                    />
                  </Grid>
                )}
                <Grid item xs={3}>
                  <TextField
                    id="standard-basic"
                    size="small"
                    type="number"
                    placeholder="Surge Multiplier"
                    label="Surge Multiplier"
                    name="multiplier"
                    variant="outlined"
                    style={gridItem}
                    onChange={onHandleChange}
                  />
                </Grid>
                {adminCountry === "all" && (
                  <Grid item>
                    <CountryRegionSelect
                      country={country}
                      setCountry={setCountry}
                      city={city}
                      setCity={setCity}
                    />
                  </Grid>
                )}
              </Grid>
              <Box sx={addButtonContainer}>
                <Button variant="contained" onClick={createEvent}>
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateSurgeEvents;
