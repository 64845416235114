import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import Button from "@mui/material/Button/Button";
import { TextField, Grid, MenuItem, Snackbar, Alert } from "@mui/material";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import CountryRegionSelect from "../../components/CountryRegionSelect";
import "./style.css";
import { createReferralPlan } from "../../apis/referralPlans";
import { getAllDiscounts } from "../../apis/discounts";
import { getDiscounts } from "../../actions/discountsAction";
import { CountryType, RegionType } from "../../types/master";
import { jwtDecode } from "jwt-decode";
const countries: Record<string, string> = {
  uk: "london-boroughs",
  france: "ile-de-france",
  "ivory-coast": "abidjan",
};
const CreateReferralPlan = () => {
  const state = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [referralData, setReferralData] = useState(state.referralPlan);
  const [discounts, setDiscounts] = useState(state.discounts);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [adminCountry, setAdminCountry] = useState<string>("all");

  const goToBack = () => navigate("/referral-plans");

  // Functions to handle the snackbar's conditional rendering
  const openSnackBar = () => {
    setOpen(true);
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };

  // Function to handle and update the inputs
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReferralData({ ...referralData, [event.target.name]: event.target.value });
  };

  // Function to handle the referral plan creation with a API request
  const onCreate = async () => {
    if (
      referralData &&
      referralData.name &&
      referralData.discount_id &&
      referralData.referral_count_required
    ) {
      let payload = {
        ...referralData,
        country_code: adminCountry && adminCountry === "all" ? country?.country_code : adminCountry,
        region_code:
          adminCountry && adminCountry === "all" ? city?.region_code : countries[adminCountry],
      };
      let res = await createReferralPlan(payload);
      if (res?.status) {
        goToBack();
      } else if (res.response) {
        setStatusMessage(res?.response?.data?.message);
        openSnackBar();
      } else {
        //req failed message
        setStatusMessage("Referral creation failed.");
        openSnackBar();
      }
    } else {
      setStatusMessage("Please fill in all the required fields.");
      openSnackBar();
    }
  };

  // Fucntion to get and update the dicounts to be populated in the dropdown
  const getDiscountData = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded.countryCode !== "all") setCountry(decoded.countryCode);
      setAdminCountry(decoded.countryCode);
      const data = await getAllDiscounts(decoded.countryCode);
      dispatch(getDiscounts(data));
      setDiscounts(data);
    }
  };

  useEffect(() => {
    getDiscountData();
    console.log("state.discounts", discounts);
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Box>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <Typography style={{ fontWeight: 700, fontSize: 18, marginBottom: "20px" }}>
                Create Referral Plan
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItem: "center",
                  marginBottom: "20px",
                }}
              >
                <Button variant="contained" onClick={goToBack} startIcon={<BackIcon />}>
                  Back
                </Button>
              </Box>

              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box
                  style={{
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={3}>
                    {adminCountry === "all" && (
                      <Grid item xs={12} md={12}>
                        <CountryRegionSelect
                          country={country}
                          setCountry={setCountry}
                          city={city}
                          setCity={setCity}
                        />
                      </Grid>
                    )}
                    <Grid item xs={3} md={4}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Name"
                        label="Name"
                        name="name"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={3} md={4}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        select
                        placeholder="Select Discount ID"
                        label="Discount ID"
                        name="discount_id"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      >
                        {discounts &&
                          discounts.map((option: any) => (
                            <MenuItem key={option._id} value={option._id}>
                              {option.promo_code}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3} md={4}>
                      <TextField
                        id="outlined-basic"
                        size="small"
                        placeholder="Required Referral Count"
                        label="Required Referral Count"
                        name="referral_count_required"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItem: "center",
                  }}
                >
                  <Button variant="contained" onClick={onCreate}>
                    Create
                  </Button>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={closeSnackBar}
                  >
                    <Alert
                      onClose={closeSnackBar}
                      severity="error"
                      sx={{ width: "100%", alignItems: "center" }}
                    >
                      {statusMessage}
                    </Alert>
                  </Snackbar>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateReferralPlan;
