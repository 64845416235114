/* Component for creating a discount */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button/Button";
import BackIcon from "@mui/icons-material/ArrowBackIos";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import CountryRegionSelect from "../../components/CountryRegionSelect";
import { Grid, Snackbar, Alert, Switch } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createDiscount } from "../../apis/discounts";
import { CountryType, RegionType } from "../../types/master";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
const countries: Record<string, string> = {
  uk: "london-boroughs",
  france: "ile-de-france",
  "ivory-coast": "abidjan",
};
const CreateDiscount = () => {
  const state = useSelector((state: any) => state);
  const navigate = useNavigate();

  const [discountData, setDiscountData] = useState(state.discount);
  const [expiryDate, setExpiryDate] = useState("");
  const [usersAllowed, setUsersAllowed] = useState("");
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);
  const [isProUsersAllowed, setIsProUsersAllowed] = useState(false);
  const [adminCountry, setAdminCountry] = useState<string>("all");

  useEffect(() => {
    //fetch all the discounts data
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = jwtDecode(token);
      if (decoded.countryCode !== "all") setCountry(decoded.countryCode);
      setAdminCountry(decoded.countryCode);
    }
  }, []);

  const goToBack = () => navigate("/discounts");
  console.log("render", open, statusMessage);
  const openSnackBar = () => {
    setOpen(true);
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountData({ ...discountData, [event.target.name]: event.target.value });
  };

  const onUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsersAllowed(event.target.value);
  };

  const onCreate = async () => {
    //check the required fields
    if (
      discountData &&
      discountData.type &&
      discountData.max_discount_amount &&
      discountData.min_discount_amount &&
      discountData.min_order_amount &&
      discountData.percentage &&
      discountData.promo_code &&
      expiryDate
    ) {
      if (
        (discountData.percentage && discountData.percentage > 100) ||
        discountData.percentage < 1
      ) {
        setStatusMessage("Percentage must be between 1 and 100");
        openSnackBar();
        return;
      }
      let users_allowed: string[] = [];
      let newData = {
        ...discountData,
        country_code: adminCountry && adminCountry === "all" ? country?.country_code : adminCountry,
        region_code:
          adminCountry && adminCountry === "all" ? city?.region_code : countries[adminCountry],
        expiry_date: expiryDate,
        is_pro_users_allowed: isProUsersAllowed,
      };
      if (usersAllowed) {
        users_allowed = usersAllowed.trim().split(",");
        newData = { ...newData, users_allowed: users_allowed };
      }
      let res = await createDiscount(newData);
      if (res?.status) {
        navigate("/discounts");
      } else {
        setStatusMessage("Please fill the data in valid format.");
        openSnackBar();
      }
    } else {
      setStatusMessage("Please fill in all the required fields.");
      openSnackBar();
    }
  };

  const onExpiryDateChange = (value: any) => {
    setExpiryDate(moment(value.toISOString()).format("YYYY-MM-DD HH:mm:ss"));
  };

  const handleProUsersAllowed = (event: any) => {
    setIsProUsersAllowed(event.target.checked);
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Box>
            <div
              style={{
                marginBottom: "20px",
              }}
            >
              <Typography style={{ fontWeight: 700, fontSize: 18, marginBottom: "20px" }}>
                Create Discount
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "start",
                  alignItem: "center",
                  marginBottom: "20px",
                }}
              >
                <Button variant="contained" onClick={goToBack} startIcon={<BackIcon />}>
                  Back
                </Button>
              </Box>

              <Box
                sx={{
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Box
                  style={{
                    padding: "20px",
                  }}
                >
                  <Grid container spacing={3}>
                    {adminCountry === "all" && (
                      <Grid item xs={6} md={8}>
                        <CountryRegionSelect
                          country={country}
                          setCountry={setCountry}
                          city={city}
                          setCity={setCity}
                        />
                      </Grid>
                    )}
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="outlined-basic"
                        size="small"
                        placeholder="Type"
                        label="Type"
                        name="type"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Enter percentage in decimal between 1 - 100"
                        label="Percentage (Decimal between 1 and 100)"
                        name="percentage"
                        variant="outlined"
                        type="number"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Minimum Order Amount"
                        label="Minimum Order Amount"
                        name="min_order_amount"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Maximum Order Amount"
                        label="Maximum Order Amount"
                        name="max_order_amount"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Minimum Discount Amount"
                        label="Minimum Discount Amount"
                        name="min_discount_amount"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Maximum Discount Amount"
                        label="Maximum Discount Amount"
                        name="max_discount_amount"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Minimum Rides Required"
                        label="Minimum Rides Required"
                        name="min_rides_required"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        required
                        id="standard-basic"
                        size="small"
                        placeholder="Promo Code"
                        label="Promo Code"
                        name="promo_code"
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onHandleChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            onChange={onExpiryDateChange}
                            label="Expiry date"
                            sx={{ width: "100%" }}
                            slotProps={{
                              textField: {
                                size: "small",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <TextField
                        id="standard-basic"
                        size="small"
                        label="Users Allowed"
                        helperText="Enter comma seperated values for user's email"
                        name="users_allowed"
                        value={usersAllowed}
                        variant="outlined"
                        style={{ marginBottom: "10px", width: "100%" }}
                        onChange={onUsersChange}
                      />
                    </Grid>
                    <Grid item xs={2} md={4}>
                      <label>
                        <b>Is Pro Users Allowed</b>
                      </label>
                      <Switch
                        checked={isProUsersAllowed}
                        aria-label="Is Pro Users Allowed"
                        onChange={handleProUsersAllowed}
                      />
                    </Grid>
                  </Grid>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItem: "center",
                  }}
                >
                  <Button variant="contained" onClick={onCreate}>
                    Create
                  </Button>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={open}
                    autoHideDuration={3000}
                    onClose={closeSnackBar}
                  >
                    <Alert
                      onClose={closeSnackBar}
                      severity="error"
                      sx={{ width: "100%", alignItems: "center" }}
                    >
                      {statusMessage}
                    </Alert>
                  </Snackbar>
                </Box>
              </Box>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateDiscount;
