import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Box,
  Grid,
  Typography,
  Modal,
  Paper,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
} from "@mui/material";
import { getAdmins, updateAdmin, addAdmin, deleteAdmin } from "../../apis/adminApi";
import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { formatTextToCapitalise } from "../../utils/util";

const defaultState = {
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  role: "admin", // Default role
  countryCode: "all",
};

const countries: Record<string, string> = {
  all: "All countries",
  uk: "UK",
  france: "France",
  "ivory-coast": "Ivory Coast",
};
const ManageAdmins = () => {
  const [admins, setAdmins] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(defaultState);
  const [isEditing, setIsEditing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Fetch admins when the component loads
  const fetchAdmins = async () => {
    const response = await getAdmins();
    if (response.status === 200 && "data" in response) {
      setAdmins(response.data);
    } else {
      if ("message" in response) {
        setSnackbarMessage(response.message);
      } else {
        setSnackbarMessage("Failed to fetch admins.");
      }
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async () => {
    const { firstName, lastName, email, password, role, countryCode } = formData;

    let missingFields = [];

    if (!firstName) missingFields.push("First Name");
    if (!lastName) missingFields.push("Last Name");
    if (!email) missingFields.push("Email");
    if (!role) missingFields.push("Role");
    if (role !== "admin" && !countryCode) missingFields.push("Country");
    // Password validation for create mode
    if (!isEditing && !password) {
      missingFields.push("Password");
    }

    if (missingFields.length > 0) {
      const message = `Please fill in the following fields: ${missingFields.join(", ")}`;
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    console.log("formdata", formData);
    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: role,
      countryCode: role === "admin" ? "all" : countryCode,
      ...("password" in formData ? { password: formData.password } : {}), // Include password only when adding a new admin
    };

    let response;
    if (isEditing) {
      // Update admin
      response = await updateAdmin(formData._id, payload);
      if (response.status === 200) {
        setSnackbarMessage("Admin updated successfully.");
        setSnackbarSeverity("success");
        fetchAdmins(); // Refresh the list
      } else {
        if ("message" in response) {
          setSnackbarMessage(response.message);
        } else {
          setSnackbarMessage("Failed to update admin.");
        }
        setSnackbarSeverity("error");
      }
    } else {
      // Add new admin
      response = await addAdmin(payload);
      if (response.status === 201) {
        setSnackbarMessage("Admin added successfully.");
        setSnackbarSeverity("success");
        fetchAdmins();
      } else {
        console.log("response: ", response);
        if ("message" in response) {
          setSnackbarMessage(response.message);
        } else {
          setSnackbarMessage("Failed to add admin.");
        }
        setSnackbarSeverity("error");
      }
    }

    setSnackbarOpen(true); // Open snackbar
    setFormData(defaultState);
    setOpen(false); // Close modal
  };

  const openForm = (admin = defaultState) => {
    setFormData(admin);
    setIsEditing(!!admin._id);
    setOpen(true);
  };

  // Handle deleting an admin
  const handleDelete = async (id: string) => {
    const response = await deleteAdmin(id);
    if (response.status === 200) {
      setSnackbarMessage("Admin deleted successfully.");
      setSnackbarSeverity("success");
      fetchAdmins(); // Refresh the list after deletion
    } else {
      if ("message" in response) {
        setSnackbarMessage(response.message);
      } else {
        setSnackbarMessage("Failed to delete admin.");
      }
      setSnackbarSeverity("error");
    }
    setSnackbarOpen(true); // Open snackbar
  };

  // Close modal
  const handleClose = () => setOpen(false);

  useEffect(() => {
    fetchAdmins();
  }, []);

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box sx={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h4">Admin User Management</Typography>
            <Button onClick={() => openForm()} variant="contained" color="primary">
              Add New Admin
            </Button>
          </Box>

          <TableContainer component={Paper} sx={{ marginTop: "20px" }}>
            <Table>
              <TableHead style={{ backgroundColor: "rgb(224, 224, 224)" }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Country</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((admin: any) => (
                  <TableRow key={admin._id}>
                    <TableCell>{`${admin.firstName} ${admin.lastName}`}</TableCell>
                    <TableCell>{admin.email}</TableCell>
                    <TableCell>{formatTextToCapitalise(admin.role)}</TableCell>
                    <TableCell>{countries[admin.countryCode]}</TableCell>
                    <TableCell align="right">
                      <Box display="flex" justifyContent="center" gap={1}>
                        {/* Center the buttons */}
                        <Button
                          onClick={() => openForm(admin)}
                          color="secondary"
                          variant="outlined"
                          size="small"
                          sx={{ marginRight: 1 }}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => handleDelete(admin._id)}
                          color="error"
                          variant="outlined"
                          size="small"
                        >
                          Delete
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Modal for form */}
          <Modal open={open} onClose={handleClose}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "white",
                padding: 4,
                borderRadius: 2,
                width: "400px",
              }}
            >
              <Typography variant="h6">{isEditing ? "Edit Admin" : "Add Admin"}</Typography>
              <form>
                <TextField
                  label="First Name"
                  value={formData.firstName}
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Last Name"
                  value={formData.lastName}
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                  fullWidth
                  margin="normal"
                  required
                />
                <TextField
                  label="Email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: isEditing,
                    disabled: isEditing,
                  }}
                  required
                />

                {isEditing && (
                  <Typography variant="body2" color="textSecondary">
                    Leave blank to keep the current password. Enter a new password to update.
                  </Typography>
                )}

                <TextField
                  label="Password"
                  required={!isEditing ? true : false}
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={togglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  select
                  label="Role"
                  value={formData.role}
                  onChange={(e) => setFormData({ ...formData, role: e.target.value })}
                  fullWidth
                  margin="normal"
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="admin">Admin</option>
                  <option value="agent">Agent</option>
                  <option value="backoffice">Back Office</option>
                </TextField>
                {formData.role !== "admin" && (
                  <TextField
                    select
                    label="Country"
                    value={formData.countryCode}
                    onChange={(e) => setFormData({ ...formData, countryCode: e.target.value })}
                    fullWidth
                    margin="normal"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    <option value="uk">UK</option>
                    <option value="france">France</option>
                    <option value="ivory-coast">Ivory Coast</option>
                  </TextField>
                )}
                <Box display="flex" justifyContent="space-between" marginTop={2}>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    {isEditing ? "Update" : "Add"}
                  </Button>
                  <Button variant="outlined" color="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </Modal>
        </Box>
      </Box>

      {/* Snackbar for feedback */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          vertical: "top", // Position from the top of the screen
          horizontal: "center", // Position in the center horizontally
        }}
        sx={{
          // Optional: Additional styles for customizations (e.g., max-width)
          ".MuiSnackbarContent-root": {
            borderRadius: "8px", // Optional: Rounded corners for a better look
          },
        }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ManageAdmins;
