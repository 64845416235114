import axios from "axios";
import { DiscountData, ReferralData } from "../types/discounts";

const baseUrl = process.env.REACT_APP_PROMOTIONS_SERVICE_BASE_URL;
const token = localStorage.getItem("token");

export const getAllReferralPlans = async (countryCode: string) => {
  let response;
  const params = {
    page: 1,
    limit: 100,
    countryCode: countryCode,
  };
  await axios
    .get(`${baseUrl}/api/v1/referrals/referral-plans/get-all`, {
      params,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });
  console.log("res::", response);
  return response;
};

export const getReferralPlan = async (id: any) => {
  let response;
  const params = {
    table: "referral_plans",
  };
  await axios
    .get(`${baseUrl}/api/v1/referrals/referral-plans/read/${id}`, {
      params,
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const updateReferralPlan = async (id: any, body: ReferralData) => {
  let response: any;
  const params = {
    table: "referral_plans",
  };
  await axios
    .patch(`${baseUrl}/api/v1/referrals/referral-plans/update/${id}`, body, {
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const createReferralPlan = async (body: ReferralData) => {
  let response: any;
  const params = {
    table: "referral_plans",
  };
  await axios
    .post(`${baseUrl}/api/v1/referrals/referral-plans/create`, body, {
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = { response: e.response, message: e.message };
    });

  return response;
};

export const deleteReferralPlan = async (id: any) => {
  let response: any;
  const params = {
    table: "referral_plans",
  };
  await axios
    .delete(`${baseUrl}/api/v1/referrals/referral-plans/delete/${id}`, {
      params,
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getUsersMetReferralPlans = async () => {
  let response;
  const params = {
    page: 1,
    limit: 100,
  };
  await axios
    .get(`${baseUrl}/api/v1/admin/users-met-with-referral-plans`, {
      headers: { Authorization: `Bearer ${token}` },
      params,
    })
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });
  console.log("res::", response);
  return response;
};

export const sendPromoCode = async (id: any) => {
  let response: any;
  await axios
    .get(`${baseUrl}/api/v1/admin/send-promo-code/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
