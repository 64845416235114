/* Component for editing a discount */

import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import SidePanel from "../../components/SidePanel";
import TopBar from "../../components/TopBar";
import {
  Grid,
  CircularProgress,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Snackbar,
  Switch,
} from "@mui/material";

import CountryRegionSelect from "../../components/CountryRegionSelect";
import { CountryType, RegionType } from "../../types/earnings";
import { getDiscount, updateDiscount } from "../../apis/discounts";

const EditDiscount = () => {
  const { discount } = useSelector((state: any) => state.discountReducer);
  const [discountData, setDiscountData] = useState(discount);
  const [usersAllowed, setUsersAllowed] = useState(discount?.users_allowed?.join());
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState<CountryType | null>(null);
  const [city, setCity] = useState<RegionType | null>(null);
  const navigate = useNavigate();
  let params = useParams();
  const discountId: string | undefined = params?.id;
  const [open, setOpen] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isProUsersAllowed, setIsProUsersAllowed] = useState(false);
  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountData({ ...discountData, [event.target.name]: event.target.value });
  };

  const onUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsersAllowed(event.target.value);
  };

  const onUpdate = async () => {
    let payload: any = {
      country_code: country?.country_code || "",
      region_code: city?.region_code || "",
      type: discountData?.type,
      percentage: discountData?.percentage,
      min_order_amount: discountData?.min_order_amount,
      max_order_amount: discountData?.max_order_amount,
      max_discount_amount: discountData?.max_discount_amount,
      min_discount_amount: discountData?.min_discount_amount,
      promo_code: discountData?.promo_code,
      min_rides_required: discountData?.min_rides_required,
      expiry_date: discountData?.expiry_date,
      users_allowed: usersAllowed ? usersAllowed.split(",") : [],
      is_pro_users_allowed: isProUsersAllowed,
    };
    if ((payload.percentage && payload.percentage > 100) || payload.percentage < 1) {
      setStatusMessage("Percentage must be between 1 and 100");
      openSnackBar();
      return;
    }
    let res = await updateDiscount(discountId, payload);
    if (res?.status) {
      navigate("/discounts");
    }
  };
  const openSnackBar = () => {
    setOpen(true);
  };
  const closeSnackBar = (event: React.SyntheticEvent | Event, reason?: string) => {
    setOpen(false);
  };
  const getDiscountById = async (id: any) => {
    setLoading(true);
    const data = await getDiscount(id);
    const localData = {
      type: data?.type,
      percentage: data?.percentage,
      min_order_amount: data?.min_order_amount,
      max_order_amount: data?.max_order_amount,
      max_discount_amount: data?.max_discount_amount,
      min_discount_amount: data?.min_discount_amount,
      min_rides_required: data?.min_rides_required,
      promo_code: data?.promo_code,
      expiry_date: data?.expiry_date,
    };
    setDiscountData(localData);
    setCountry(data?.country_code);
    setCity(data?.region_code);
    let users = data?.users_allowed?.join();
    setUsersAllowed(users);
    setLoading(false);
    setIsProUsersAllowed(data?.is_pro_users_allowed);
  };

  useEffect(() => {
    const id = discountId !== undefined ? discountId : "";
    getDiscountById(id);
  }, []);

  const handleProUsersAllowed = (event: any) => {
    setIsProUsersAllowed(event.target.checked);
  };

  return (
    <Box className="mainContainer">
      <Box className="sidePanelContainer">
        <SidePanel isOpen={true} onClose={() => {}} />
      </Box>
      <Box className="dashboardContainer">
        <TopBar />
        <Box style={{ width: "100%", padding: "20px", marginTop: "5%" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <Typography style={{ fontWeight: 700, fontSize: 20, marginBottom: "20px" }}>
                  Update Discount
                </Typography>

                <Box
                  sx={{
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                  }}
                >
                  <Box
                    style={{
                      padding: "20px",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={6} md={8}>
                        <CountryRegionSelect
                          country={country}
                          setCountry={setCountry}
                          city={city}
                          setCity={setCity}
                          isEditing={true}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="outlined-basic"
                          size="small"
                          placeholder="Type"
                          label="Type"
                          name="type"
                          variant="outlined"
                          value={discountData?.type}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          required
                          id="standard-basic"
                          size="small"
                          placeholder="Enter percentage in decimal between 1 - 100"
                          label="Percentage (Decimal between 1 and 100)"
                          name="percentage"
                          variant="outlined"
                          value={discountData?.percentage}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Minimum Order Amount"
                          label="Minimum Order Amount"
                          name="min_order_amount"
                          value={discountData?.min_order_amount}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Maximum Order Amount"
                          label="Maximum Order Amount"
                          name="max_order_amount"
                          value={discountData?.max_order_amount}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Minimum Discount Amount"
                          label="Minimum Discount Amount"
                          name="min_discount_amount"
                          variant="outlined"
                          value={discountData?.min_discount_amount}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Maximum Discount Amount"
                          label="Maximum Discount Amount"
                          name="max_discount_amount"
                          variant="outlined"
                          value={discountData?.max_discount_amount}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Minimum Rides Required"
                          label="Minimum Rides Required"
                          name="min_rides_required"
                          variant="outlined"
                          value={discountData?.min_rides_required}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Promo Code"
                          label="Promo Code"
                          name="promo_code"
                          variant="outlined"
                          value={discountData?.promo_code}
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          placeholder="Expiry Date"
                          label="Expiry Date"
                          name="expiry_date"
                          value={discountData?.expiry_date}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onHandleChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <TextField
                          id="standard-basic"
                          size="small"
                          label="Users Allowed"
                          helperText="Enter comma seperated values for user's email"
                          name="users_allowed"
                          value={usersAllowed}
                          variant="outlined"
                          style={{ marginBottom: "10px", width: "100%" }}
                          onChange={onUsersChange}
                        />
                      </Grid>
                      <Grid item xs={2} md={4}>
                        <label>
                          <b>Is Pro Users Allowed</b>
                        </label>
                        <Switch
                          checked={isProUsersAllowed}
                          aria-label="Is Pro Users Allowed"
                          onChange={handleProUsersAllowed}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItem: "center",
                    }}
                  >
                    <Snackbar
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={open}
                      autoHideDuration={3000}
                      onClose={closeSnackBar}
                    >
                      <Alert
                        onClose={closeSnackBar}
                        severity="error"
                        sx={{ width: "100%", alignItems: "center" }}
                      >
                        {statusMessage}
                      </Alert>
                    </Snackbar>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItem: "center",
                    }}
                  >
                    <Button
                      style={{ marginRight: "4px", backgroundColor: "gray" }}
                      variant="contained"
                      onClick={() => {
                        navigate("/discounts");
                      }}
                    >
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={onUpdate}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </div>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EditDiscount;
