// Importing necessary modules from React Router and React
import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

// Importing components/screens used in the application
import Login from "./screens/Login";
import Dashboard from "./screens/Dashboard";
import Register from "./screens/Register";
import ForgotPassword from "./screens/ForgotPassword";
import Discounts from "./screens/Discounts";
import DriverDocumentation from "./screens/DriverDocumentation";
import DriverDetails from "./screens/DriverDocumentation/DriverDetails";
import Pricing from "./screens/Pricing";
import Earnings from "./screens/Earnings";
import ReferralPlans from "./screens/ReferralPlans";
import ReferralTracker from "./screens/ReferralTracker";
import SurgeEvents from "./screens/SurgeEvents";
import CreateDiscount from "./screens/Discounts/CreateDiscount";
import EditDiscount from "./screens/Discounts/EditDiscount";
import CreateReferralPlan from "./screens/ReferralPlans/CreateReferralPlan";
import EditEarning from "./screens/Earnings/EditEarnings";
import EditReferralPlan from "./screens/ReferralPlans/EditReferralPlan";
import EditPricing from "./screens/Pricing/EditPricing";
import CreateSurgeEvents from "./screens/SurgeEvents/CreateSurgeEvents";
import EditSurgeEvents from "./screens/SurgeEvents/EditSurgeEvents";
import ProtectedRoutes from "./ProtectedRoutes";
import NotFound from "./screens/NotFound";
import WebSocketComponent from "./screens/WebSocketUI";
import CreatePricing from "./screens/Pricing/CreatePricing";
import { jwtDecode } from "jwt-decode";
import { EditorProvider } from "react-simple-wysiwyg";
import AddEditNotificationTemplate from "./screens/NotificationTemplates/NotificationTemplateForm";
import UserManagement from "./screens/users/UserManagement";
import NotificationTemplateManagement from "./screens/NotificationTemplates/NotificationTemplatesList";
import DriverList from "./screens/DriverDocumentation/DriverList";
import ManageAdmins from "./screens/admins/AdminManage";
// Main Router component
const Router = () => {
  const [decodedToken, setDecodedToken] = useState<any>(null);
  // Checking if a user is logged in by retrieving the token from local storage
  const token = localStorage.getItem("token");
  const decoded: any = token && jwtDecode(token);
  const role = decoded && decoded.role;
  // Navigation hook from React Router
  let navigate = useNavigate();

  // Example driver details, could be fetched dynamically based on authentication
  const driverDetails = {
    id: 1,
    full_name: "John Doe",
    driver_license_number: "AD56566666",
    car_id: "XY343232",
    total_number_of_rides: 40,
    availability: "XYZ",
    documents: "DD20",
  };

  // useEffect hook (currently empty)
  useEffect(() => {
    // Add any side effects or initializations if needed
    if (token) {
      const decoded = jwtDecode(token);
      setDecodedToken(decoded);
    }
  }, [token]);
  // JSX structure for defining routes using React Router
  return (
    <div>
      <Routes>
        {/* Route for the homepage, showing either the Dashboard or Login based on the token */}
        <Route
          path="/"
          element={
            token && role ? (
              role === "admin" ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/driver-documentation" />
              )
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/login" element={<Login />} />
        {/* Protected routes that require authentication */}
        <Route element={<ProtectedRoutes />}>
          {/* Routes for various screens and actions */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/register-new-user" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/discounts"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? <Discounts /> : <NotFound />
            }
          />
          <Route
            path="/discounts/create"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <CreateDiscount />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/discounts/edit/:id"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? <EditDiscount /> : <NotFound />
            }
          />
          <Route
            path="/referral-plans"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <ReferralPlans />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/referral-plans/create"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <CreateReferralPlan />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/referral-plans/edit/:id"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <EditReferralPlan />
              ) : (
                <NotFound />
              )
            }
          />
          <Route path="/driver-documentation" element={<DriverList />} />
          <Route
            path="/driver-documentation/:id"
            element={
              <EditorProvider>
                <DriverDetails />
              </EditorProvider>
            }
          />
          <Route
            path="/pricing"
            element={decodedToken?.role === "admin" ? <Pricing /> : <NotFound />}
          />
          <Route
            path="/pricing/create"
            element={decodedToken?.role === "admin" ? <CreatePricing /> : <NotFound />}
          />
          <Route
            path="/pricing/edit/:id"
            element={decodedToken?.role === "admin" ? <EditPricing /> : <NotFound />}
          />
          <Route
            path="/surge-events"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? <SurgeEvents /> : <NotFound />
            }
          />
          <Route
            path="/surge-events/create"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <CreateSurgeEvents />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/surge-events/edit/:id"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <EditSurgeEvents />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/earnings-and-management"
            element={decodedToken?.role === "admin" ? <Earnings /> : <NotFound />}
          />
          <Route
            path="/earnings-and-management/edit/:id"
            element={decodedToken?.role === "admin" ? <EditEarning /> : <NotFound />}
          />
          <Route
            path="/referral-tracking"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <ReferralTracker />
              ) : (
                <NotFound />
              )
            }
          />
          <Route path="/websocket-ui" element={<WebSocketComponent />} />
          <Route
            path="/notifications"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <NotificationTemplateManagement />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/admins"
            element={decodedToken?.role === "admin" ? <ManageAdmins /> : <NotFound />}
          />
          <Route
            path="/users"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <UserManagement />
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/notifications/create"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <EditorProvider>
                  <AddEditNotificationTemplate />{" "}
                </EditorProvider>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/notifications/edit/:id"
            element={
              ["admin", "backoffice"].includes(decodedToken?.role) ? (
                <EditorProvider>
                  <AddEditNotificationTemplate />{" "}
                </EditorProvider>
              ) : (
                <NotFound />
              )
            }
          />
        </Route>

        {/* Catch-all route for handling 404 (Not Found) errors */}
        <Route path="*" element={<NotFound />}></Route>
      </Routes>
    </div>
  );
};

// Exporting the Router component
export default Router;
