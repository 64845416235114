import axios from "axios";
import { SurgeEvent } from "../types/surgeEvents";

const baseUrl = process.env.REACT_APP_ORDERS_SERVICE_BASE_URL;

export const getAllSurgePricing = async (countryCode: string) => {
  let response;
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(
      `${baseUrl}/api/v1/dynamic-pricing/get-all?page=1&limit=10&countryCode=${countryCode}`,
      config
    )
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const getSurgePricing = async (id: string) => {
  let response;
  let token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  await axios
    .get(`${baseUrl}/api/v1/dynamic-pricing/${id}`, config)
    .then((res) => {
      response = res.data.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const createSurgePricingEvent = async (body: SurgeEvent) => {
  let response: any;
  let token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  await axios
    .post(`${baseUrl}/api/v1/dynamic-pricing/create`, body, config)
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const updateSurgePricingEvent = async (body: SurgeEvent, id: any) => {
  let response: any;
  let token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  await axios
    .patch(`${baseUrl}/api/v1/dynamic-pricing/${id}`, body, config)
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};

export const deleteSurgePricingEvent = async (id: any) => {
  let response: any;
  let token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  await axios
    .delete(`${baseUrl}/api/v1/dynamic-pricing/${id}`, config)
    .then((res) => {
      response = res.data;
    })
    .catch((e) => {
      console.log("Error:", e);
      response = {};
    });

  return response;
};
